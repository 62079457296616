import {
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import {
  catchError,
  filter,
  finalize,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
// import { LoadingProgressBarService } from "../services/loading-progress-bar.service";

// it stores all requests & removes when request completed
const reqArr: any[] = [];

let refreshTokenInProgress = false;
const refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
  null,
);

export const authInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn,
): Observable<HttpEvent<any>> => {
  const authService = inject(AuthService);
  // const loadingProgressBarService = inject(LoadingProgressBarService);

  if (authService.getLocalstorage('accessToken')) {
    if (authService.getLocalstorage('accessToken')) {
      req = req.clone({
        headers: req.headers.set(
          'Authorization',
          'Bearer ' + authService.getLocalstorage('accessToken'),
        ),
      });
    }
  }

  const closeProgressBar = (req: any) => {
    const i = reqArr.indexOf(req);
    if (i >= 0) {
      reqArr.splice(i, 1);
      if (!reqArr.length) {
        // loadingProgressBarService.hideLoadingBar();
      }
    }
  };

  return next(req).pipe(
    tap((event) => {
      if (!reqArr.includes(req)) {
        reqArr.push(req);
      }
      // loadingProgressBarService.showLoadingBar();
      if (event instanceof HttpResponse) {
        //
      }
    }),
    catchError((error) => {
      const needToCheckUrl = !authService.authURLs.find((url) =>
        req.url.match(url),
      );      
      closeProgressBar(req);
      if (
        needToCheckUrl &&
        error?.status === 401 &&
        error?.statusText === 'Unauthorized'
      ) {
        if (!refreshTokenInProgress) {
          refreshTokenInProgress = true;
          refreshTokenSubject.next(null);
          if (authService.isTokenValid('refreshToken')) {
            return authService.refreshToken().pipe(
              switchMap((response: any) => {
                refreshTokenInProgress = false;
                refreshTokenSubject.next(response?.data?.access?.token);
                authService.setAccessRefreshToken(response?.data);
                req = req.clone({
                  headers: req.headers.set(
                    'Authorization',
                    `Bearer ${response?.data?.access?.token}`,
                  ),
                });
                return next(req);
              }),
              catchError((err) => {
                refreshTokenInProgress = false;
                if (err?.code === 601) {
                  authService.logout();
                }
                return throwError(err);
              }),
            );
          } else {            
            authService.logout();
          }
        } else {
          return refreshTokenSubject.pipe(
            filter((token) => token != null),
            take(1),
            switchMap((token) => {
              req = req.clone({
                headers: req.headers.set('Authorization', `Bearer ${token}`),
              });
              return next(req);
            }),
          );
        }
      }

      return throwError(error);
    }),
    finalize(() => {
      closeProgressBar(req);
    }),
  );
};
