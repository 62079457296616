<header class="main-header d-flex flex-wrap flex-md-row justify-content-between">
    <div class="d-flex align-items-center justify-content-start ">	
        <!-- Logo -->
        <a href="index.html" class="logo">
          <!-- logo-->
            <div class="logo-mini w-40">
                <span class="light-logo"><img src="images/logo-letter.png" alt="logo"></span>
                <span class="dark-logo"><img src="images/logo-letter.png" alt="logo"></span>
            </div>
            <div class="logo-lg">
                <span class="light-logo"><img src="images/logo-dark-text.png" alt="logo"></span>
                <span class="dark-logo"><img src="images/logo-light-text.png" alt="logo"></span>
            </div>
            
        </a>
        
        <ul class="header-megamenu nav d-xl-flex" [class.active]="isMenuActive">
            @for (nav of navList; track $index) {
                <li class="btn-group nav-item"  routerLinkActive="active">
                    <a [routerLink]="[nav?.route]"  class="nav-link px-2 ">{{nav?.label}}</a>
                </li>
            }
            <!-- <li class="btn-group nav-item ">
                <a  class="nav-link px-2 ">Trending</a>
            </li>
            <li class="btn-group nav-item ">
                <a  class="nav-link px-2 ">Hottest Industries</a>
            </li>
            <li class="btn-group nav-item ">
                <a [routerLink]="['/news']" class="nav-link px-2 ">News</a>
            </li>
            <li class="btn-group nav-item ">
                <a  class="nav-link px-2 ">Chatbot</a>
            </li> -->
        </ul> 
         
        
    </div>  
    <!-- Header Navbar -->
        
        <div class="navbar-custom-menu d-flex align-items-center justify-content-center justify-content-md-end  flex-grow-1">
            <ul class="nav ac-balance-info flex-nowrap">
                <li class="text-center">
                    <h6 class="fw-bold mb-0">Total Invested Amount</h6>
                    <h4 class="fw-bold my-0 text-secondary">{{totalInvestedValue?.portfolioBalance | currency:"USD"}}</h4>
                </li>
            </ul>	
        </div>

        <nav class="navbar navbar-static-top">
            <!-- Sidebar toggle button-->
            <div class="navbar-custom-menu d-flex align-items-center">
                <ul class="nav navbar-nav d-flex flex-nowrap">	
                    
                    <li ngbDropdown >
                        <a  class="waves-effect waves-light dropdown-toggle btn-primary-light" ngbDropdownToggle title="Search">
                            <small><i class="bi bi-search"></i></small>
                        </a>
                        <ul class="animated bounceIn" ngbDropdownMenu>
                            <li>
                                <form>
                                    <div class="input-group flex-nowrap">
                                    <input type="search" class="form-control border-0 page-searchbox"  placeholder="Search" aria-label="Search" aria-describedby="button-addon2">
                                    <div class="input-group-append">
                                        <button class="btn" type="submit" id="button-addon3"><i class="bi bi-search"></i></button>
                                    </div>
                                    </div>
                                </form>
                            </li>
                        </ul>
                        

                    </li>
                    
                    <!-- Notifications -->
                    <li class="dropdown notifications-menu">
                        <a  class="waves-effect waves-light dropdown-toggle btn-primary-light" data-bs-toggle="dropdown" title="Notifications">
                            <i class="bi bi-bell-fill"></i>
                        </a>
                        <ul class="dropdown-menu animated bounceIn">
            
                        <li class="header">
                            <div class="p-20">
                                <div class="flexbox">
                                    <div>
                                        <h4 class="mb-0 mt-0">Notifications</h4>
                                    </div>
                                    <div>
                                        <a  class="text-danger">Clear All</a>
                                    </div>
                                </div>
                            </div>
                        </li>
            
                        <li>
                            <!-- inner menu: contains the actual data -->
                            <ul class="menu sm-scrol">
                            <li>
                                <a>
                                <i class="fa fa-users text-info"></i> Curabitur id eros quis nunc suscipit blandit.
                                </a>
                            </li>
                            <li>
                                <a >
                                <i class="fa fa-warning text-warning"></i> Duis malesuada justo eu sapien elementum, in semper diam posuere.
                                </a>
                            </li>
                            <li>
                                <a >
                                <i class="fa fa-users text-danger"></i> Donec at nisi sit amet tortor commodo porttitor pretium a erat.
                                </a>
                            </li>
                            <li>
                                <a >
                                <i class="fa fa-shopping-cart text-success"></i> In gravida mauris et nisi
                                </a>
                            </li>
                            <li>
                                <a >
                                <i class="fa fa-user text-danger"></i> Praesent eu lacus in libero dictum fermentum.
                                </a>
                            </li>
                            <li>
                                <a >
                                <i class="fa fa-user text-primary"></i> Nunc fringilla lorem 
                                </a>
                            </li>
                            <li>
                                <a >
                                <i class="fa fa-user text-success"></i> Nullam euismod dolor ut quam interdum, at scelerisque ipsum imperdiet.
                                </a>
                            </li>
                            </ul>
                        </li>
                        <li class="footer">
                            <a >View all</a>
                        </li>
                        </ul>
                    </li>	
                    
                    <!-- User Account-->
                    <li class="dropdown user user-menu">
                        <a  class="waves-effect waves-light dropdown-toggle text-decoration-none btn-primary-light p-2" data-bs-toggle="dropdown" title="User">
                            <img [src]="userDetail?.photo | imageDomainChange" alt="photo" width="50" height="50" class="img-fluid" style="vertical-align:baseline;">
                        </a>
                        <ul class="dropdown-menu animated flipInX">
                        <li class="user-body">
                            <a class="dropdown-item" [routerLink]="['/profile']"><i class="ti-user text-muted me-2"></i> Profile</a>
                            <a class="dropdown-item" ><i class="ti-wallet text-muted me-2"></i> My Wallet</a>
                            <a class="dropdown-item" ><i class="ti-settings text-muted me-2"></i> Settings</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item"  class="cursor-pointer text-decoration-none" (click)="authService.logout()"><i class="ti-lock text-muted me-2 "></i> Logout</a>
                        </li>
                        </ul>
                    </li>	

                    <li class="p-0 m-0">
                        <a class="toggle-menubar d-xl-none pt-10" (click)="toggleMenu()">
                          <span class="bi bi-list"></span>
                        </a>
                      </li>
                        
                </ul>
            </div>
    </nav>
  </header>