export const watchlistColumn = [
  {
    field: 'ticker',
    label: 'Name',
    isComponentSide: true,
    type: 'string',
    isSortable: false,
  },
  {
    field: 'todaysChange',
    label: 'Change',
    isComponentSide: true,
    type: 'string',
    isSortable: false,
    columnClass: 'text-end',
    columnWidth:80
  },
  {
    field: 'todaysChangePerc',
    label: 'Change(%)',
    isComponentSide: true,
    type: 'percentage',
    isSortable: false,
    columnClass: 'text-end',
    columnWidth:80
  },
  {
    field: 'price',
    label: 'Price',
    isComponentSide: true,
    type: 'string',
    isSortable: false,
    columnClass: 'text-end',
    columnWidth:80
  },
];

export const watchlistStockDataList = [
  {
    ticker: 'SOUTHBANK',
    change: '-0.35',
    changePerc: '-2.03',
    price: '16.90',
  },
  {
    name: 'SOUTHBANK',
    change: '-0.35',
    changePerc: '-2.03',
    price: '16.90',
  },
  {
    name: 'SOUTHBANK',
    change: '-0.35',
    changePerc: '-2.03',
    price: '16.90',
  },
  {
    name: 'SOUTHBANK',
    change: '-0.35',
    changePerc: '-2.03',
    price: '16.90',
  },
  {
    name: 'SOUTHBANK',
    change: '-0.35',
    changePerc: '-2.03',
    price: '16.90',
  },
  {
    name: 'SOUTHBANK',
    change: '-0.35',
    changePerc: '-2.03',
    price: '16.90',
  },
  {
    name: 'SOUTHBANK',
    change: '-0.35',
    changePerc: '-2.03',
    price: '16.90',
  },
];
