import { Component, OnInit } from '@angular/core';
import { NavigationCancel, NavigationError, RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit{
  title = 'IMA-web';
  isLoading=false
  constructor(private router:Router){

  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof RouteConfigLoadStart) {
        const routePath = event?.route?.path || '';
        if (
          !routePath.includes('loading') &&
          !routePath.includes('dashboard')
        ) {
          this.isLoading = true;
        }
      } else if (
        event instanceof RouteConfigLoadEnd ||
        event instanceof NavigationError ||
        event instanceof NavigationCancel
      ) {
        this.isLoading = false;
      }
    });
  }
}
