import { CommonModule, CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, effect, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ImageDomainChangePipe } from '../../pipes/image-domain-change.pipe';
import { AuthService } from '../../services/auth.service';
import { navDataList } from './header.data';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-main-header',
  standalone: true,
  imports: [
    CommonModule,
    NgbDropdownModule,
    RouterModule,
    ImageDomainChangePipe,
    CurrencyPipe,
  ],
  templateUrl: './main-header.component.html',
  styleUrl: './main-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainHeaderComponent implements OnInit {
  navList = navDataList;
  userDetail: any;
  isMenuActive = false;
  totalInvestedValue: any;

  constructor(
    public authService: AuthService,
    private cdr: ChangeDetectorRef,
    private commonService: CommonService,
  ) {
    // Get total portfolio balance to display
    effect(() => {
      this.totalInvestedValue = this.commonService.totalPortfolioValue();
      this.cdr.markForCheck();
    });
  }

  ngOnInit(): void {
    this.userDetail = this.authService.decryptedUserDetails();
    this.commonService.updateTotalInvestedValue();
    this.cdr.markForCheck();
  }


  toggleMenu() {
    this.isMenuActive = !this.isMenuActive;
    this.cdr.markForCheck();
  }
}
