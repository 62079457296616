import { CommonModule, DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  NgbDropdownModule,
  NgbModal,
  NgbModule,
  NgbNavModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { debounceTime, filter, map, Subscription } from 'rxjs';
import { PAGINATION_OPTIONS } from '../../../../config/app.constant';
import { CommonConfirmModalComponent } from '../../../shared/components/common-confirm-modal/common-confirm-modal.component';
import { CommonModalComponent } from '../../../shared/components/common-modal/common-modal.component';
import { CustomDataTableComponent } from '../../../shared/components/custom-data-table/custom-data-table.component';
import { StockBuySellModalComponent } from '../../../shared/components/stock-buy-sell-modal/stock-buy-sell-modal.component';
import { StockTableComponent } from '../../../shared/components/stock-table/stock-table.component';
import { UppercaseWordDirective } from '../../../shared/directives/uppercase-word.directive';
import { HighlightWordPipe } from '../../../shared/pipes/highlight-word.pipe';
import { PriceChangesPipe } from '../../../shared/pipes/price-changes.pipe';
import { AuthService } from '../../../shared/services/auth.service';
import { CommonService } from '../../../shared/services/common.service';
import { DashboardService } from '../../../shared/services/dashboard.service';
import { SocketIoService } from '../../../shared/services/socket-io.service';
import { watchlistColumn } from './watchlist.data';
import { PriceDifferencePipe } from '../../../shared/pipes/price-difference.pipe';

@Component({
  selector: 'app-watchlist-stock',
  standalone: true,
  imports: [
    CommonModule,
    NgbNavModule,
    CustomDataTableComponent,
    StockTableComponent,
    NgbModule,
    NgbDropdownModule,
    ReactiveFormsModule,
    FormsModule,
    HighlightWordPipe,
    NgbTooltipModule,
    PriceChangesPipe,
    NgxSkeletonLoaderModule,
    UppercaseWordDirective,
    RouterModule,
    CommonModalComponent,
    PriceDifferencePipe,
  ],
  templateUrl: './watchlist-stock.component.html',
  styleUrl: './watchlist-stock.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WatchlistStockComponent implements OnInit, OnDestroy {
  subscriptions$: Subscription[] = [];
  isSocketLive: any = false;
  isCreateModalOpen = false;
  actionType: any;
  watchListName: any;
  watchList: any[] = [];
  userDetail: any;
  displayedColumnArray: any[] = watchlistColumn;
  watchlistData: any[] = [];
  tableOptions = {
    fetchingData: false,
    page: PAGINATION_OPTIONS.INITIAL_PAGE_INDEX,
    limit: PAGINATION_OPTIONS.INITIAL_PAGE_SIZE,
    totalRecord: 0,
    notDataMessage: 'Data Not Found!',
    showTotalRecords: false,
    showPagination: false,
  };
  stockIndexes: any = {};
  searchControl = new FormControl();
  activeId = 1;
  activeWatchlistId: any;
  resultSets: any;
  isStockListDisplay: any = false;
  isLoading: any = false;
  @ViewChild('searchStock') searchStock!: ElementRef;
  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event) {
    if (this.searchStock) {
      const clickedInside = this.searchStock.nativeElement.contains(
        event.target,
      );
      if (!clickedInside) {
        this.searchControl.reset();
        this.isStockListDisplay = false;
      }
    }
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef,
    private dashboardService: DashboardService,
    private authService: AuthService,
    private commonService: CommonService,
    private socketService: SocketIoService,
  ) {
    effect(() => {
      if (socketService?.socketOnmessage()?.type == 'ticker_data') {
        this.isSocketLive = true;
        const getSocketData: any = socketService.socketOnmessage()?.data;
        if (getSocketData && getSocketData?.ev == 'T') {
          const getIndex = this.stockIndexes[getSocketData?.sym];
          if (getIndex >= 0 && this.watchlistData[getIndex]?.lastTrade?.p) {
            this.watchlistData[getIndex].lastTrade.p = getSocketData?.p;
            cdr.markForCheck();
          }
        }
        cdr.markForCheck();
      } else {
        this.isSocketLive = false;
        cdr.markForCheck();
      }
    });
  }

  /**
   * ngOnInit - Initialize get WatchList and UserDetail
   */
  ngOnInit(): void {
    const storedActiveId = this.getStoredWatchList();
    this.activeId = storedActiveId?.currentWatchList
      ? Number(storedActiveId?.currentWatchList)
      : 1;
    this.getWatchList();
    this.userDetail = this.authService.decryptedUserDetails();
    this.onSearch();
    this.cdr.markForCheck();
  }

  /**
   * onNavChange - Change watchlist and call getWatchListById API to fetch new list
   * @param event Nav tab changeEvent
   */
  onNavChange(event: any) {
    this.isLoading = true;
    this.activeId = event.nextId;
    this.storeSelectedWatchList(this.activeId.toString());
    this.activeWatchlistId = this.watchList[this.activeId - 1]?._id;
    this.getWatchListById();
    this.cdr.markForCheck();
  }

  /**
   * onSearch - function can search with debounce and call the searchStock api
   */
  onSearch() {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(300),
        map((value) => {
          if (!value || value.length < 1) {
            this.resultSets = null;
            this.searchControl.reset();
            this.isStockListDisplay = false;
          }
          return value;
        }),
        filter((value) => value && value.length >= 1),
      )
      .subscribe((value) => {
        this.isStockListDisplay = true;
        this.getSearchList({
          search: value,
          watchlistId: this.activeWatchlistId,
        });
      });
  }

  /**
   * openEditModal - This function will open modal for delete watchlist
   * @param watchlistName Existing Watchlist name
   */
  openDeleteModal(watchlistName: string) {
    const modalRef = this.deleteConfirmModal();

    modalRef.componentInstance.modalType = `WatchList`;
    modalRef.componentInstance.type = `${watchlistName} WatchList`;
    modalRef.componentInstance.actionType = 'delete';
    // modalRef.componentInstance.watchlistName = watchlistName;
    modalRef.result.then(
      (result) => {
        if (result) {
          this.removeWatchList();
        }
      },
      () => {
        // console.log('Dismissed:', reason);
      },
    );
  }

  /**
   * getWatchList - Call GetWatchList API
   */
  getWatchList() {
    this.isLoading = true;
    const getWatchListApi = this.dashboardService.getWatchList().subscribe({
      next: (res: any) => {
        if (res?.data && res?.type == 'success') {
          this.watchList = res?.data || [];
          if (this.watchList.length > 0) {
            this.activeWatchlistId =
              this.watchList[this.activeId - 1]?._id || '';
            this.watchlistData =
              this.watchList[this.activeId - 1]?.stockDetails || [];
            this.stockIndexes = this.commonService.storeStockPosition(
              this.watchlistData,
            );
          }
          this.isLoading = false;
          this.cdr.markForCheck();
        }
      },
      error: () => {
        this.isLoading = false;
        this.cdr.markForCheck();
      },
    });
    this.subscriptions$.push(getWatchListApi);
  }

  /**
   * addWatchList - Call Add WatchList API
   * @param watchListData Add WatchList-Data
   */
  addWatchList(watchListData: any) {
    const addWatchListApi = this.dashboardService
      .addWatchList({ name: watchListData })
      .subscribe({
        next: (res: any) => {
          if (res?.data && res?.type == 'success') {
            this.getWatchList();
            this.activeId = this.watchList?.length + 1;
            this.storeSelectedWatchList(this.activeId.toString());
            this.cdr.markForCheck();
          }
        },
        error: () => {
          this.cdr.markForCheck();
        },
      });
    this.subscriptions$.push(addWatchListApi);
  }
  /**
   * updateWatchList - Call Update WatchList API
   * @param watchListData Updated WatchListData
   */
  updateWatchList(watchListData: any) {
    const updateWatchListApi = this.dashboardService
      .updateWatchList(this.activeWatchlistId, {
        name: watchListData,
        tickers: [],
      })
      .subscribe({
        next: (res: any) => {
          if (res?.data && res?.type == 'success') {
            this.getWatchList();
            this.cdr.markForCheck();
          }
        },
        error: () => {
          this.cdr.markForCheck();
        },
      });
    this.subscriptions$.push(updateWatchListApi);
  }
  /**
   * removeWatchList - Call remove WatchList API
   * @param watchListId remove WatchListID
   */
  removeWatchList() {
    const removeWatchListApi = this.dashboardService
      .removeWatchList(this.activeWatchlistId)
      .subscribe({
        next: (res: any) => {
          if (res?.data && res?.type == 'success') {
            this.getWatchList();
            if (this.activeId > 1) {
              this.activeId = this.activeId - 1;
            } else {
              this.activeId = 1;
            }
            this.storeSelectedWatchList(this.activeId.toString());
            this.cdr.markForCheck();
          }
        },
        error: () => {
          this.cdr.markForCheck();
        },
      });
    this.subscriptions$.push(removeWatchListApi);
  }

  /**
   * getSearchList - Get the search list API
   */
  getSearchList(queryParams: any) {
    this.dashboardService.searchStock(queryParams).subscribe({
      next: (res: any) => {
        if (res?.data) {
          this.resultSets = res?.data?.results || [];
          this.cdr.markForCheck();
        } else {
          this.resultSets = [];
          this.cdr.markForCheck();
        }
      },
      error: () => {
        this.resultSets = [];
        this.cdr.markForCheck();
      },
    });
  }
  /**
   * getWatchListById - Call GetWatchlist by ID API
   */
  getWatchListById() {
    const watchlistId = this.watchList[this.activeId - 1]?._id;

    const getWatchListByIdApi = this.dashboardService
      .getWatchListById(watchlistId)
      .subscribe({
        next: (res: any) => {
          if (res?.data && res?.type == 'success') {
            this.watchlistData = res?.data?.stockDetails || [];
            this.stockIndexes = this.commonService.storeStockPosition(
              this.watchlistData,
            );
            this.isLoading = false;
            this.cdr.markForCheck();
          }
        },
        error: () => {
          this.isLoading = false;
          this.cdr.markForCheck();
        },
      });
    this.subscriptions$.push(getWatchListByIdApi);
  }
  /**
   * addStockToWatchList - Call AddStockToWatchList API
   * @param suggestion ticker Data
   * @param event stopPropagation of existing event
   */
  addStockToWatchList(suggestion: any, event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    const watchListData = {
      ticker: suggestion?.ticker,
      type: suggestion?.type || suggestion?.toShow ? 'remove' : 'add',
    };
    const addStockToWatchListApi = this.dashboardService
      .updateTicker(this.watchList[this.activeId - 1]?._id, watchListData)
      .subscribe({
        next: (res: any) => {
          if (res?.data && res?.type == 'success') {
            suggestion.toShow = suggestion?.toShow ? false : true;
            this.getWatchListById();
            this.cdr.markForCheck();
          }
        },
        error: () => {
          this.cdr.markForCheck();
        },
      });
    this.subscriptions$.push(addStockToWatchListApi);
  }
  /**
   * onRemoveStock - Call remove Stock from watchList API
   * @param event Output event of table
   */
  onRemoveStock(ticker: any) {
    const watchListData = {
      ticker: ticker,
      type: 'remove',
    };
    this.addStockToWatchList(watchListData);
    this.cdr.markForCheck();
  }

  /**
   * storeSelectedWatchList - store the selected WatchList in localStorage
   * @param id WatchList id
   * @param name WatchList name
   */
  storeSelectedWatchList(_id: string) {
    const getStoredWatchList = this.getStoredWatchList();
    getStoredWatchList.currentWatchList = _id;
    this.authService.setUserDetails(getStoredWatchList);
  }

  /**
   * getStoredWatchList - retrieve the stored WatchList from localStorage
   * @returns stored WatchList or null
   */
  getStoredWatchList() {
    const storedWatchList = this.authService.decryptedUserDetails();
    return storedWatchList;
  }

  /**
   * stockBuySell - open modal for add stock in portfolio
   */
  stockBuySell(selectedStock: any, type: any) {
    const modalRef = this.modalService.open(StockBuySellModalComponent, {
      size: 'md',
      centered: true,
    });
    modalRef.componentInstance.stockData = {
      ticker: selectedStock?.ticker,
      price:selectedStock?.lastTrade?.p,
      transactionType:type
    };

    modalRef.result.then(
      () => {
        this.commonService.updateTotalInvestedValue();
        // this.closeResult = `Closed with: ${result}`;
      },
      () => {
        // this.closeResult = 'Dismissed'
      },
    );
  }

  /**
   * watchListAction - change type of model
   * @param type modal type
   */
  watchListAction(type: any, watchListName?: any) {
    this.watchListName = watchListName;
    if (type == 'Create' || type == 'Edit') {
      this.actionType = type;
      this.isCreateModalOpen = true;
    } else if (type == 'delete') {
      this.actionType = 'Delete';
    }
  }

  /**
   * deleteConfirmModal - delete modal confirmation
   * @returns boolean value
   */
  deleteConfirmModal() {
    const modalRef = this.modalService.open(CommonConfirmModalComponent, {
      centered: true,
    });
    return modalRef;
  }

  /**
   * actionOnWatchList - change the action of WatchList
   * @param value - type of WatchList
   */
  actionOnWatchList(value: any) {
    if (value) {
      if (this.actionType == 'Create') {
        this.addWatchList(value);
      } else if (this.actionType == 'Edit') {
        this.updateWatchList(value);
      }
    }
    this.isCreateModalOpen = false;
  }

  /**
   * ngOnDestroy - Unsubscribe subscription variable
   */
  ngOnDestroy(): void {
    this.subscriptions$.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
  }
}
