import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-auth-header',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './auth-header.component.html',
  styleUrl: './auth-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthHeaderComponent implements OnInit {
  isUserLogin = false;
  constructor(
    public authService: AuthService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    const getToken = this.authService.getLocalstorage('accessToken');
    const getRefreshToken = this.authService.getLocalstorage('refreshToken');
    if (getToken && getRefreshToken) {
      this.authService.isUserLogin.set(true);
    } else {
      this.authService.isUserLogin.set(false);
    }
    this.cdr.markForCheck();
  }

  logoutUser() {
    this.authService.logout();
    this.authService.isUserLogin.set(false);
    this.cdr.markForCheck();
  }
}
