export const navDataList=[
    {
        label:'Dashboard',
        route:'/dashboard'
    },
    {
        label:'Trending',
        route:'/trending'
    },
    {
        label:'Hottest Industries',
        route:'/industries'
    },
    {
        label:'News',
        route:'/news'
    },
    {
        label:'Chatbot',
        route:'/chat'
    },
]