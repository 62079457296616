<div class="hold-transition light-skin no-sidebar">
    <app-main-header></app-main-header>
    <div class="content-wrapper">
        <div class="container-full left-fixed-col">
            <aside class="wishlist-section border-end">
                <app-watchlist-stock></app-watchlist-stock>
            </aside>
            <div class="main-content-section">
                <router-outlet />
            </div>
        </div>
    </div>
    <div class="py-2 border-top footer mt-auto">
        <app-footer></app-footer>
    </div>
</div>


