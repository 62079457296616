import { Routes } from '@angular/router';
import { AuthComponent } from './authentication/auth.component';
import { MainComponent } from './main/main.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { NoAuthGuard } from './shared/guards/noAuth.guard';

export const routes: Routes = [
  {
    path: 'auth',
    component: AuthComponent,
    canActivate: [NoAuthGuard],
    loadChildren: () =>
      import('./authentication/auth.routes').then((r) => r.authRoute),
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/main.routes').then((r) => r.mainRoute),
  },
  {
    path: 'error',
    loadComponent: () =>
      import('../app/error/error.component').then((c) => c.AppErrorComponent),
  },
  {
    path: '**',
    redirectTo: 'error',
    pathMatch: 'full',
  },
];
