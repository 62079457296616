import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FooterComponent } from '../shared/components/footer/footer.component';
import { MainHeaderComponent } from '../shared/components/main-header/main-header.component';
import { WatchlistStockComponent } from './dashboard/watchlist-stock/watchlist-stock.component';
import { SocketIoService } from '../shared/services/socket-io.service';

@Component({
  selector: 'app-main',
  standalone: true,
  imports: [RouterOutlet,MainHeaderComponent,FooterComponent,WatchlistStockComponent],
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss',
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class MainComponent implements OnInit{


  constructor(private socketService:SocketIoService,private cdr:ChangeDetectorRef){

  }
  ngOnInit(): void {
    this.socketService.socketIoConnection()
    this.cdr.markForCheck()
  }
}
