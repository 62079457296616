import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { inject, PLATFORM_ID } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

export const NoAuthGuard: CanActivateFn | CanActivateChildFn = async () => {
  const router: Router = inject(Router);
  const authService = inject(AuthService);
  const platformId = inject(PLATFORM_ID);
  const getRefreshToken = await authService.getLocalstorage('refreshToken');
  const getAccessToken = authService.getLocalstorage('accessToken');
  if (isPlatformServer(platformId)) {
    //
  } else if (isPlatformBrowser(platformId)) {
    if (
      getRefreshToken &&
      getAccessToken&&authService.isRegistrationCompleted()
    ) {
      router.navigateByUrl('');
      return false;
    } else {
      return true;
    }
  }
  return false;
};
